import { render, staticRenderFns } from "./FormInputCategory.vue?vue&type=template&id=c11f2450&"
import script from "./FormInputCategory.vue?vue&type=script&lang=js&"
export * from "./FormInputCategory.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports