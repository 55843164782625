<template>
  <div id="home">
      <Form />
  </div>
</template>

<script>
import Form from '@/components/User/FormEdit.vue'

export default {
  components: {
    Form
  },
  methods: {
  },
  mounted () {
  }
}
</script>

<style>

</style>
